import React from 'react';

import styles from './SubscriptionStar.css';
import { SubscriptionStarIcon } from '../Icons/SubscriptionStarIcon';

export const SubscriptionStar = ({ percentsSaved }) => {
    const SAVE_VALUE_PERCENT = '41%';

    return (
        <div className={styles.container}>
            <SubscriptionStarIcon />
            <div className={styles.content}>
                Save
                <br />
                {percentsSaved ? `${percentsSaved}%` : SAVE_VALUE_PERCENT}
            </div>
        </div>
    );
};
