import React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';

import { ArrowDownIcon } from '../../atoms/Icons/Styleguide/ArrowDownIcon';
import { Responsive } from '../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { UrlService } from '../../services/UrlService';
import styles from './GemsPromoBlock.css';

const TITLE = 'Enjoy even more with Gems';
const CONTENT =
    'Use gems to skip ads or buy boosts, themes, and more to use in your favorite games. Arkadium Advantage subscribers save 30% on gem purchases.';
const ALT_TEXT = 'Gems promo image';
const IMG_SRC = `${UrlService.toCDNUrl('/images/gems/gems_promo_block.png')}`;

const accordionStyles = {
    '&.MuiAccordion-root:last-of-type': {
        borderRadius: 'inherit',
    },
    '&.MuiAccordion-root:first-of-type': {
        borderRadius: 'inherit',
    },
    backgroundColor: 'inherit',
    color: 'inherit',
    boxShadow: 'none',
};

const accordionSummaryStyles = {
    '& .MuiAccordionSummary-content': {
        margin: '1rem 0',
    },
};

const accordionTypographyStyles = {
    fontWeight: 600,
};

const GemsPromoBlock = React.memo(() => {
    return (
        <>
            <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE_FIGMA_ABOVE}>
                <div className={styles.container}>
                    <div className={styles.imgBlock}>
                        <img src={IMG_SRC} alt={ALT_TEXT} loading="lazy"/>
                    </div>
                    <div className={styles.textBlock}>
                        <p className={styles.title}>{TITLE}</p>
                        <p className={styles.content}>{CONTENT}</p>
                    </div>
                </div>
            </Responsive>
            <Responsive maxWidth={ArkCssBreakpoints.ARK_SMALL_MOBILE_FIGMA}>
                <div className={styles.container}>
                    <Accordion disableGutters={true} sx={accordionStyles}>
                        <AccordionSummary
                            expandIcon={<ArrowDownIcon className={styles.arrow} />}
                            aria-controls="accordion-content"
                            id="accordion-header"
                            sx={accordionSummaryStyles}
                        >
                            <Typography sx={accordionTypographyStyles}>{TITLE}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{CONTENT}</Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Responsive>
        </>
    );
});
GemsPromoBlock.displayName = 'GemsPromoBlock';
export default GemsPromoBlock;
