import React, { FC, useEffect } from 'react';

import { push } from 'connected-react-router';
import { Trans, useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './SelectSubscription.css';
import { AppLoader } from '../../../../atoms/AppLoader/AppLoader';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { NavLink } from '../../../../atoms/Link/Link';
import { PaymentType, RecurlyPlans } from '../../../../constants/RecurlyPurchase';
import { getPlanByCode, SubscriptionPlans } from '../../../../constants/SubscriptionPlan';
import { ArkadiumAdvantageLogo } from '../../../../FigmaStyleguide/Icons/ArkadiumAdvantageLogo';
import { ListCheckCircleIcon } from '../../../../FigmaStyleguide/Icons/ListCheckCircleIcon';
import { AnnualCard } from '../../../../molecules/AnnualCard/AnnualCard';
import { MonthlyCard } from '../../../../molecules/MonthlyCard/MonthlyCard';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { UrlService } from '../../../../services/UrlService';
import { setActiveSubscriptionPlan } from '../../../../store/ducks/subscription/common';
import { getSubscriptionPlans } from '../../../../templates/Profile/Tabs/PaymentAndSubscription/JoinBlock/JoinBlock';

export const SelectSubscription: FC = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const plans = useSelector(({ subscriptionPlans }) => subscriptionPlans);
  const subscriptionSource = useSelector(({ subscriptionSource }) => subscriptionSource);
  const annualPlanName = RecurlyPlans[PaymentType.subscription][SubscriptionPlans.ANNUAL].plan;
  const monthlyPlanName = RecurlyPlans[PaymentType.subscription][SubscriptionPlans.MONTHLY].plan;

  SelectSubscription.displayName = 'SelectSubscription';
  const COLOR_CIRCLE_COLOR = 'rgba(11, 16, 61, 1)'; // HEX #0B103D
  const handleSubscribe = (plan: string) => {
    void Analytics.trackEvent(
      Analytics.subscription.entryPointClick(
        subscriptionSource,
        plan === SubscriptionPlans.MONTHLY ? 'monthly' : 'annual'
      )
    );
    batch(() => {
      dispatch(setActiveSubscriptionPlan(plan));
      dispatch(push('/subscription'));
    });
  };

  useEffect(() => {
    if (!plans) {
      getSubscriptionPlans(dispatch, null);
    }
  }, [plans]);

  return (
    <>
      <div className={styles.topBlock}>
        <div className={styles.mainInfo}>
          <div className={styles.leftSide}>
            <ArkadiumAdvantageLogo />
          </div>
          <div className={styles.rightSide}>
            <ul className={styles.advantagesList}>
              <li>
                <ListCheckCircleIcon goldCheck checkColor={COLOR_CIRCLE_COLOR} />
                <p>{t('SELECT_SUBSCRIPTION.PLAY_GAMES_WITHOUT_ADS')}</p>
              </li>
              <li>
                <ListCheckCircleIcon goldCheck checkColor={COLOR_CIRCLE_COLOR} />
                <p>
                  <span className={styles.advantageLabel}>30%</span>&nbsp;off gem purchases
                </p>
              </li>
              <li>
                <ListCheckCircleIcon goldCheck checkColor={COLOR_CIRCLE_COLOR} />
                <p>
                  {t('SELECT_SUBSCRIPTION.SHOW_PERSONALITY')}&nbsp;
                  <span className={styles.advantageLabel}>{t('SELECT_SUBSCRIPTION.AVATARS')}&nbsp;</span>
                  {t('SELECT_SUBSCRIPTION.AND')}&nbsp;
                  <span className={styles.advantageLabel}>{t('SELECT_SUBSCRIPTION.FRAMES')}</span>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.advantagesRow}>
        {plans?.length ? (
          <>
            <AnnualCard
              plan={getPlanByCode(annualPlanName, plans)}
              monthlyPlan={getPlanByCode(monthlyPlanName, plans)}
              btnLabel={t('SELECT_SUBSCRIPTION.SUBSCRIBE_BTN_LABEL')}
              handlerBtn={() => handleSubscribe(SubscriptionPlans.ANNUAL)}
            />
            <MonthlyCard
              plan={getPlanByCode(monthlyPlanName, plans)}
              btnLabel={t('SELECT_SUBSCRIPTION.SUBSCRIBE_BTN_LABEL')}
              handlerBtn={() => handleSubscribe(SubscriptionPlans.MONTHLY)}
            />
          </>
        ) : (
          <AppLoader />
        )}
      </div>

      <div className={styles.aboutRow}>
        <div className={styles.aboutSubscription}>
          <p>
            <Trans i18nKey="SELECT_SUBSCRIPTION.RENEW_SUBSCRIPTION_TEXT" />
            <NavLink to="/arkadium-player-agreement.pdf" target="_blank" rel="noopener">
              <I18nText keyName="PLAYER_AGREEMENT" />
            </NavLink>{','}&nbsp;
            <NavLink to="/arkadium-advantage-agreement.pdf" target="_blank" rel="noopener">
              <I18nText keyName="SUBSCRIPTION_AGREEMENT" />
            </NavLink>&nbsp;
            {t('SELECT_SUBSCRIPTION.AND')}&nbsp;
            <NavLink to={UrlService.createURL('privacy-policy')} target="_blank" rel="noopener">
              <I18nText keyName="FOOTER_PRIVACY_POLICY" />
            </NavLink>
          </p>
        </div>
      </div>
    </>
  );
});
