import { CONSTS } from '@arkadium/modules';

import { Environment, EnvironmentName } from '../constants/Environment';

export const environment: Environment = {
    Name: EnvironmentName.BRANCH,
    // Blobs
    GAME_API_URL: 'https://arenacloud.cdn.arkadiumhosted.com/game-api-origin-live',
    GAMES_CONTAINER_URL: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-games',
    SORTING_URL: 'https://arenaxstoragedev.blob.core.windows.net/arkcom-games-sorting-analytics/arkadium.com_auto.json',
    GAME_ARTS:
        'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/_arena-shared-content_/arkcom-game-arts',
    GAME_METAS: 'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-game-metas',

    WHYNOTTRYTHESE_URL:
        'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arkcom-games-whynottrythese-analytics/arkadium.json',
    CONNECTED_APPS_URL:
        'https://arenaxstoragedev.blob.core.windows.net/arenax-index/_arena-shared-content_/ark-connected-apps/arkadium-connected-apps.json',
    HOMEPAGE_GAMES_URL:
        'https://arenaxstoragedev.blob.core.windows.net/arenax-index/_arena-shared-content_/ark-homepage-games/game-lists.local.json',
    HOMEPAGE_GAMES_URL_REDESIGN:
        'https://arenaxstoragedev.blob.core.windows.net/arenax-index/_arena-shared-content_/ark-homepage-games/game-lists-redesign.local.json',
    GAMES_SORTED_BY_GAME_STARTS:
        'https://arenaxstoragedev.blob.core.windows.net/arenax-index/_arena-shared-content_/ark-games-sorted-by-game-starts/ark-games-sorted-by-game-starts.json',
    IFRAME_GAMES_LIST_PATH:
        'https://arenaxstorage.blob.core.windows.net/arenax-index/_arena-shared-content_/arkcom-iframe-games/iframe-games-list.json',
    GRM_GAMES_LIST_PATH: CONSTS.GRM_GAMES_LIST_URL.arkcom.dev,
    AB_TESTS:
        'https://arenaxstoragedev.blob.core.windows.net/arenax-index/_arena-shared-content_/arkcom-abtests/arena-data-abtest.json',
    ARK_CONFIG: 'https://arenaxstoragedev.blob.core.windows.net/arenax-index/www.arkadium.com/ark_config.json',

    //
    FEED_ID: '18',
    FEED_NAME: 'ArkCom_Local',
    /* eslint-disable */
    ARENA_DOMAIN: process.env.ARENA_DOMAIN || 'www.test.arkadium-sandbox.com',
    SITE_BASE_URL: process.env.SITE_BASE_URL || '/',
    // AD
    AD_REFRESH_TIME_MS: 30000, // 30 sec
    // CDN
    USE_CDN: false,
    CDN_BASE_URL_OVERWRITES: '',
    // APP INSIGHT
    APP_INSIGHT_INSTRUMENTATION_KEY: 'fcd509a6-647a-4f3a-bb33-538b6f6efe54', // azure name: arkadiumcom-appins-dev
    APP_INSIGHT_INSTRUMENTATION_KEY_FRONT: '392da680-3f8f-49e8-908b-c3346a0ab692', // azure name: arkadium.com-dev
    VALIDATE_ANALYTICS_TRACKS: false,
    /* eslint-disable-next-line */

  ADO_BUILD_ID: ADO_BUILD_ID,
  // local and session storage prefix
  STORAGE_PREFIX: 'arkcom:',
  // determine wether to show server errors in browser with details or simple message
  ERROR_DETAILS: true,
  // cache time for memored service
  CACHE_TIME_MS: 10 * 1000, // 10 seconds;
  PAGE_SEO_URL:
    'https://arenaxstorage.blob.core.windows.net/arenax-index/_arena-shared-content_/ark-pages-seo/categories-seo.local.json',
  NON_CAT_PAGE_SEO:
    'https://arenaxstorage.blob.core.windows.net/arenax-index/_arena-shared-content_/ark-pages-seo/non-categories-seo.local.json',
  CO_BRANDED_PAGE_SEO:
    'https://arenaxstoragedev.blob.core.windows.net/arenax-index/_arena-shared-content_/ark-pages-seo/co-branded-pages-seo.local.json',
  AFFILIATE_DATA:
    'https://arenaxstoragedev.blob.core.windows.net/arenax-index/_arena-shared-content_/ark-affiliate-data/',
  STRAPI_URL: 'https://arenacloud.cdn.arkadiumhosted.com/strapi-dev',
  STRAPI_API_KEY:
    '3128586df02d9402150df9e789327dd9bc1ec90fb1215ee3ced4cb8eaa94fa74393be73cd18707824148abf5234e3f3c47732226a50b20a5029b256fa3288ca2ca71d32ff63c615098f895672c1204951f618cbabb6af165bd514299f1b3e4c2689e1380438fb2d5f6be178743555393f3a484600137653f2f9e6c49eee16a86',
  // used for upload path
  PATH: '/src/public/uploads',
  REDIRECTS_SETTINGS_PATH:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-global-settings/redirects/redirects.json',
  PUSH_NOTIFICATIONS_URL: `https://arenacloud.cdn.arkadiumhosted.com/arenaxstorage-blob/arenax-index/www-dev.arkadium.com/push-notification/`,

  // api urls
  USER_API_BASE_URL: `https://arenacloud.cdn.arkadiumhosted.com/uup-api-user-dev/api/v1`,
  SCORE_API_BASE_URL: `https://arenacloud.cdn.arkadiumhosted.com/uup-api-score-dev/api/v1`,
  BLOB_IMAGES_BASE_URL: `https://arenaxstorage.blob.core.windows.net/arenax-index/`,
  EAGLE_USER_GAME_DATA_API: CONSTS.EAGLE_USER_GAME_DATA_API_ENDPOINT.dev,

  // PROD
  // USER_API_BASE_URL: `https://arenacloud.cdn.arkadiumhosted.com/uup-api-user-prod/api/v1`,
  // SCORE_API_BASE_URL: `https://arenacloud.cdn.arkadiumhosted.com/uup-api-score-prod/api/v1`,

  EAGLE_API_BASE_URL: `https://eagle-user-api-dev.arkadiumhosted.com/`,
  EAGLE_API_PAYMENT: `https://eagle-payment-api.uup-aks-dev.arkadiumhosted.com`,
  RECURLY_BILLING_PAGE: `https://arkadium-test.recurly.com/account/`,

  EAGLE_API_COLLECTIONS_URL: 'https://eagle-virtual-item-api.uup-aks-dev.arkadiumhosted.com/',

  DISPLAY_ADS: 'https://ams.cdn.arkadiumhosted.com/advertisement/display/stable/quantcast/display-ads.js',
  VIDEO_ADS: 'https://ams.cdn.arkadiumhosted.com/advertisement/video/stable/quantcast/video-ads.js',
  ADS_QUERY_PARAM: '__arkads',
  ADS_TXT: 'https://arenaxstoragedev.blob.core.windows.net/arenax-index/_arena-shared-content_/arkcom-adstxt/ads.txt',

  RECURLY_PUBLIC_KEY: 'ewr1-UsfGfKZUxJUBRuGX7PQnOT',
  RECURLY_PLAN_PREFIX: 'test____',
  RECURLY_GEMS_PREFIX: 'test__',
  RECURLY_GIFT_CARD_PRODUCT_CODE: 'test_gift',
  // LEANPLUM
  LEANPLUM_APP_ID: 'app_rqFURnfZa50YE8vo3g3YnDLgaPNKNEFbMtlGFogTL7o',
  LEANPLUM_API_KEY: 'dev_WiAyPqL3MvqEiHUdlLhnpUIlHDI35ejKBLMpkT7AjFI',

  // dev key
  OPEN_WEB_KEY: 'sp_r3W90Mbs',

  // Skip ad product SKU name
  SKU_GEMS_SKIP_AD: 'test__skip_ad',
  SKU_GEMS_SKIP_AD_BUNDLE: 'test__skip_ad_bundle',
  SKU_GEMS_NAME: 'pseudo-coins',

  //HotJar ID
  HOTJAR_SITE_ID: 0,

  //Tag Manager
  TAGMANAGER_SITE_ID: '',

  //Recaptcha Key ID
  RECAPTCHAAPPKEY: '6LfIkwAkAAAAACnKy6-B7zMmDkOeH2JK-7u72sHu',
  CHALLENGE_CAPTCHA_KEY: '6Lff9RknAAAAAGsrf0Os5WfIJlE91oCiEQn1CvLR',
  CDN_CAPTCHA_IFRAME:
    'https://arenacloud.cdn.arkadiumhosted.com/arenaxstoragedev-blob/arenax-3rd-party-scripts/captcha/iframe-captcha-dev.html',

  //hardcoded nest env for games team (still haven't decided yet, just for testing for now)
  NEST_ENVIRONMENT: 'dev',
};
